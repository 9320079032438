// vendor
import React, { useContext } from 'react';
import { Tooltip } from '@dealsyte/poki';

// Components
import {
  FormFilePicker,
  FormGroup,
  Input,
  Select,
  SingleOptionRadioButtons,
  Text,
  TextArea,
} from '../../../../common/components';

// Icon
import { RoundedInfoIcon } from '../../../../common/icons';


import { MMLabels, DocumentsTypesOptions, DistributionMethodsOptions, ReferenceDealOptions, OneDealInfo, acceptedFiles } from './MarketingMaterialFormConstants';
import {
  MarketingMaterialForm as MarketingMaterialFormType,
  MarketingMaterialHandlerContext,
} from './MarketingMaterialFormHandler';
import { formControlChangeHandlers } from '../../FormControlChangeHandlers/FormControlChangeHandlers';

const MarketingMaterialForm = () => {
  const { form, findOptionInList, hasMassiveDistributionMethods } = useContext(MarketingMaterialHandlerContext);
  const { values, setFieldValue, errors, touched } = form;

  const {
    handleFieldChange,
    handleFilesChange,
    handleSelectChange,
    handleYesNoChange,
  } = formControlChangeHandlers<MarketingMaterialFormType>(setFieldValue);

  const hasDistributionMethod = values.distributionMethod?.length > 0

  return (
    <>
      <FormFilePicker
        label={MMLabels.FILE_UPLOAD}
        files={values.files}
        setFiles={handleFilesChange('files')}
        error={errors.files ? errors.files : ''}
        acceptedFiles={acceptedFiles}
        required
        description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
      />
      <FormGroup label={MMLabels.IS_RELATED_ANY_FIRMS_DEALS} required>
        <SingleOptionRadioButtons
          strictValueOnChange
          id="isRelatedToAnyFirmDeal"
          name="isRelatedToAnyFirmDeal"
          value={values.isRelatedToAnyFirmDeal}
          onChange={handleYesNoChange('isRelatedToAnyFirmDeal')}
        />
      </FormGroup>
      {values.isRelatedToAnyFirmDeal === true && (
        <FormGroup label={MMLabels.SPECIFIC_OR_MULTIPLE_DEAL}
          required
          error={touched.specificOrMultipleDeal && errors.specificOrMultipleDeal}
        >
          <Select
            inputId="specificOrMultipleDeal"
            name="specificOrMultipleDeal"
            maxMenuHeight={200}
            placeholder="Select deal scope"
            isSearchable={false}
            options={ReferenceDealOptions}
            value={values.specificOrMultipleDeal}
            onChange={handleSelectChange('specificOrMultipleDeal')}
          />
        </FormGroup>
      )}
      {values.isRelatedToAnyFirmDeal === true && values.specificOrMultipleDeal?.value === 'ONE_DEAL' ?
        <Text variant='value' style={{ color: '#e84747' }}>{OneDealInfo}</Text> :
        (
          <>
            <FormGroup
              label={MMLabels.DOCUMENT_TYPE}
              required
            >
              <Select
                inputId="documentType"
                name="documentType"
                maxMenuHeight={200}
                placeholder="Select type"
                isSearchable={false}
                options={DocumentsTypesOptions}
                value={values.documentType}
                onChange={handleSelectChange('documentType')} />
            </FormGroup>
            {values.documentType !== null && values.documentType.value === 'OTHER' && (
              <FormGroup
                id="otherDocument"
                showCharCount
                label={MMLabels.OTHER_DOCUMENT}
                error={touched.otherDocument && errors.otherDocument}
              >
                {ref => (
                  <Input
                    required
                    ref={ref as any}
                    id="otherDocument"
                    maxLength={140}
                    name="otherDocument"
                    placeholder="Other document"
                    value={values.otherDocument}
                    onChange={handleFieldChange('otherDocument')}
                  />
                )}
              </FormGroup>
            )}
            <FormGroup label={MMLabels.DISTRIBUTION_METHOD} required>
              <Select
                inputId="distributionMethod"
                name="distributionMethod"
                maxMenuHeight={200}
                placeholder="Select method"
                isSearchable={false}
                isMulti
                options={DistributionMethodsOptions}
                value={values.distributionMethod}
                onChange={handleSelectChange('distributionMethod')}
              />
            </FormGroup>
            {hasDistributionMethod &&
              findOptionInList(values.distributionMethod, 'OTHER') && (
                <FormGroup
                  id="otherMethod"
                  required
                  showCharCount
                  label={MMLabels.OTHER_METHOD}
                  error={touched.otherMethod && errors.otherMethod}
                >
                  {ref => (
                    <Input
                      ref={ref as any}
                      id="otherMethod"
                      maxLength={140}
                      name="otherMethod"
                      placeholder="Other method"
                      value={values.otherMethod}
                      onChange={handleFieldChange('otherMethod')}
                    />
                  )}
                </FormGroup>
              )}
            {hasDistributionMethod && !hasMassiveDistributionMethods(values.distributionMethod) && (
                <FormGroup
                label={
                  <span>
                    {MMLabels.EXCLUSIVE_INVESTORS}
                    <Tooltip
                      message={MMLabels.EXCLUSIVE_INVESTORS_INFO}
                      style={{ padding: 20, lineHeight: 1.5 }}
                    >
                      <RoundedInfoIcon style={{ marginLeft: 4, marginBottom: 3, color: '#6A678E' }} />
                    </Tooltip>
                  </span>
                }
                required
              >
                  <SingleOptionRadioButtons
                    strictValueOnChange
                    id="exclusiveInvestors"
                    name="exclusiveInvestors"
                    value={values.exclusiveInvestors}
                    onChange={handleYesNoChange('exclusiveInvestors')}
                  />
                </FormGroup>
              )}

            {hasDistributionMethod && values?.exclusiveInvestors === false && (
              <>
                <FormGroup label={MMLabels.MASS_DISTRIBUTION} required>
                  <SingleOptionRadioButtons
                    strictValueOnChange
                    id="massDistribution"
                    name="massDistribution"
                    value={values.massDistribution}
                    onChange={handleYesNoChange('massDistribution')}
                  />
                </FormGroup>
              </>
            )}
            {hasDistributionMethod && (values.massDistribution === true ||
              hasMassiveDistributionMethods(values.distributionMethod)) && (
                <>
                  <FormGroup label={MMLabels.FINANCIAL_RECOMMENDATION} required>
                    <SingleOptionRadioButtons
                      strictValueOnChange
                      id="financialRecommendation"
                      name="financialRecommendation"
                      value={values.financialRecommendation}
                      onChange={handleYesNoChange('financialRecommendation')}
                    />
                  </FormGroup>
                </>
              )}
            {hasDistributionMethod && values?.exclusiveInvestors === false && (
              <>
                <FormGroup label={MMLabels.NATURAL_PERSONS_AUDIENCE} required>
                  <SingleOptionRadioButtons
                    strictValueOnChange
                    id="naturalPersonsAudience"
                    name="naturalPersonsAudience"
                    value={values.naturalPersonsAudience}
                    onChange={handleYesNoChange('naturalPersonsAudience')}
                  />
                </FormGroup>
              </>
            )}

            <FormGroup label="Additional notes:">
              <TextArea
                value={values.note}
                onChange={handleFieldChange('note')}
              />
            </FormGroup>
          </>
        )
      }
    </>
  );
};

export default MarketingMaterialForm;
