import styled from 'styled-components';

export const MessageContainer = styled.div`
  margin-top: 32px;
`;

export const Bubble = styled.div`
  background-color: ${({ isAuthor }: { isAuthor?: boolean }) =>
    isAuthor ? '#edf0fd;' : '#f9f9fb'};
  padding: 16px;
  border-radius: 0px 6px 6px 6px;
  min-height: 56px;
  overflow-wrap: anywhere;
  line-height: 20px;
  &.isAuthor {
    background: #edf0fd;
  }
`;

export const MessageText = styled.p`
  display: box;
  font-family: Sofia Pro;
  font-size: 14px;
  line-height: 24px;
  color: #2b3664;
`;

export const Text = styled.pre`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: normal;
  overflow-wrap: anywhere;
`;

export const MessageAttachmentContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  padding: 12px 16px;
  border: 1px solid #dae1fb;
  border-radius: 6px;
  margin-top: 16px;
`;

export const MessageAttachmentItem = styled.div`
  display: flex;
  align-items: center;
  font-family: Sofia Pro;
  font-size: 12px;
  color: #2b3664;
  margin-right: 12px;
  cursor: pointer;
  &:last-child {
    margin-right: 0px;
  }
`;

export const AuthorLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const AuthorLabel = styled.div`
  font-family: Sofia Pro;
  font-size: 16px;
  color: #2b3664;
  font-weight: 700;
  margin-right: 12px;
`;

export const TimeStamp = styled.div`
  font-family: Sofia Pro;
  font-size: 12px;
  color: #414e81;
  margin-top: 10px;
  padding: 0px 16px;
  &.noPadding {
    padding: 0px;
  }
`;

export const EventMessageContainer = styled.div`
  margin-top: 10px;
`;

export const EventMessageBubble = styled.div`
  display: flex;
  border: 1px solid #edf0fd;
  border-radius: 6px;
  padding: 16px;
`;

export const EventMessageLeftSection = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 16px;
`;

export const EventMessageRightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const EventMessageIconContainer = styled.div`
  border-radius: 12px;
  background: #edf0fd;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EventMessageBodyContainer = styled.div`
  display: flex;
`;

export const EventMessageBody = styled.p`
  display: inline-flex;
  align-items: center;
  font-family: Sofia Pro;
  color: #2b3664;
  font-size: 14px;
`;

export const EventMessageAuthorLabel = styled(EventMessageBody)`
  font-weight: 700;
`;
