// vendors
import * as yup from 'yup';

const collaborationTypeValidationSchema = yup.object().shape({
  comment: yup
    .string()
    .transform(value => (value ? value.trim() : ''))
    .required('Please provide a comment.')
    .min(1, 'Please provide a comment.'),
  collaborateType: yup.string(),
});

export default collaborationTypeValidationSchema;
