import React from 'react';
import dayjs from 'dayjs';
import _noop from 'lodash/noop';
import Linkify from 'linkify-react';

import {
  AuthorLabel,
  AuthorLabelContainer,
  Bubble,
  MessageAttachmentContainer,
  MessageAttachmentItem,
  MessageContainer,
  MessageText,
  TimeStamp,
  Text,
  FileNameText,
} from './MessageStyled';
import { AttachmentIcon } from '../../icons';

interface MessageProps {
  message: {
    author: string;
    isAuthor?: boolean;
    body: string;
    attachments: { name: string; link: string; deleted?: boolean }[];
    createdAt: string;
  };
  onAttachmentClick?: (attachment: { name: string; link: string }) => void;
  userBadge?: () => JSX.Element;
}

const linkifyOptions = {
  attributes: {
    target: '_blank',
  },
  formatHref: href => {
    if (!href.startsWith('http://') && !href.startsWith('https://')) {
      return `https://${href}`;
    }
    if (href.startsWith('http://')) {
      return href.replace('http://', 'https://');
    }
    return href;
  },
  validate: (value, type) => {
    if (type === 'url') {
      const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?(\?.*)?$/;
      return domainRegex.test(value) || value.startsWith('http://') || value.startsWith('https://');
    }
    return false;
  },
};

export const Message = ({
  onAttachmentClick = _noop,
  userBadge,
  message,
}: MessageProps): JSX.Element => {
  const { author, isAuthor, body, attachments: noteAttachments, createdAt } = message;
  const attachments = noteAttachments.filter(attachment => !attachment.deleted);
  return (
    <MessageContainer>
      <AuthorLabelContainer>
        <AuthorLabel>{author}</AuthorLabel>
        {userBadge && userBadge()}
      </AuthorLabelContainer>
      <Bubble isAuthor={isAuthor}>
        <MessageText>
          <Text>
            <Linkify options={linkifyOptions}>{body}</Linkify>
          </Text>
        </MessageText>
        {attachments && attachments.length > 0 && (
          <MessageAttachmentContainer>
            {attachments.map((item, index) => (
              <MessageAttachmentItem
                key={`attachment_${index + 1}`}
                onClick={() => onAttachmentClick(item)}
              >
                <AttachmentIcon color="#2C6EF2" style={{ marginRight: 5, flexShrink: 0 }} />
                <p>{item.name}</p>
              </MessageAttachmentItem>
            ))}
          </MessageAttachmentContainer>
        )}
      </Bubble>
      <TimeStamp>{dayjs(createdAt).format('HH:mm · MMM DD, YYYY')}</TimeStamp>
    </MessageContainer>
  );
};
